import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AccessoryImage from "~/accessory-image";

export default ({ product, ...props }) => (
    <Link
        to={`/sale/${product.slug.current}/`}
        className="flex flex-col no-underline group hover:no-underline"
        {...props}
    >
        <AccessoryImage className="overflow-hidden rounded-xl">
            <GatsbyImage
                className="overflow-hidden rounded-xl"
                image={product.images[0].asset.gatsbyImageData}
                alt={product.images[0].alt || ""}
            />
        </AccessoryImage>

        <div className="flex flex-col flex-grow md:text-sm lg:text-base m-xxs md:m-sm">
            <h3 className="m-0 font-bold text-inherit ">{product.title}</h3>
            {product.condition && <span className="mt-2px">{product.condition ?? ""}</span>}
            {product.price && (
                <span className="flex-wrap content-end flex-grow mt-4">
                    EUR {new Intl.NumberFormat("de-DE").format(product.price)}
                </span>
            )}
        </div>
    </Link>
);
