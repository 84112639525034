import React from "react";
import { graphql } from "gatsby";
import Column from "~/column";
import Container from "~/container";
import Intro from "~/intro";
import Layout from "~/layout";
import ProductItem from "~/product-item";
import SEO from "~/seo";

const SalePage = (props) => {
    const { data } = props;

    return (
        <Layout>
            <Intro title="Sale">
                Bereichern Sie Ihr Zuhause mit geschmackvollen Möbeln aus unserer Ausstellung.
            </Intro>
            <Container
                spacing={true}
                className="space-y-gutter -mt-lg xs:flex xs:flex-wrap xs:space-y-0 md:-mt-xl xl:-mt-2xl 2xl:-mt-3xl"
            >
                {data.products.edges.map((edge) => (
                    <Column
                        className="flex flex-wrap text-base pb-md xs:w-1/2 sm:pb-lg md:py-gutter md:w-1/3 md:text-base lg:w-1/4 xl:text-lg"
                        key={edge.node.id}
                    >
                        <ProductItem product={edge.node} />
                    </Column>
                ))}
            </Container>
        </Layout>
    );
};

export const Head = () => {
    return (
        <SEO
            title="Sale"
            description="Bereichern Sie Ihr Zuhause mit geschmackvollen Möbeln aus unserer Ausstellung."
        />
    );
};

export const query = graphql`
    query ProductQueryPage {
        products: allSanityProduct(sort: { fields: orderRank }, filter: { visible: { eq: true } }) {
            edges {
                node {
                    id
                    slug {
                        current
                    }
                    title
                    price
                    condition
                    images {
                        alt
                        asset {
                            gatsbyImageData(width: 320, height: 320, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
    }
`;

export default SalePage;
